import React, { FC, useState, FormEvent } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import stylesheet, { Blue, Green } from '../constants/stylesheet';
import fonts from '../constants/fonts';
// display: flex;
//     padding: 5vh;
//     font-weight: bold;
//     flex-direction: column;
//     transform: translate(-50%,-50%) rotate(-90deg);
//     width: 100vh;
//     transform-origin: center;
//     height: 100vw;
//     box-sizing: border-box;
//     margin-left: 50vw;
//     margin-top: 50vh;
const useStyles = makeStyles({
  root: {

  },

  error: {
    fontSize: '0.8rem',
    color: 'red',
    marginTop: '1rem'
  },
  label: {
    ...stylesheet.label,
    width: '100%'
  },
  button: {
    flex: '1 1 auto',
    ...stylesheet.button
  },
  references: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  reference: {
    padding: '0 1rem',
  }
});

type MultibancoSource = stripe.Source & {
  "multibanco": {
    "reference": string;
    "entity": string;
  }
};
const PayByMultibanco: FC<{ stripe: stripe.Stripe, invoice: TInvoice, onSuccess: () => void; }> = (props) => {
  const cls = useStyles();
  const { invoice, stripe } = props;

  const [mbSource, setMbSource] = useState<MultibancoSource>();

  const [error, setError] = useState<string>();

  const onSubmitForm = async (ev: FormEvent) => {
    ev.preventDefault();

    if (!stripe || !invoice) { return; }
    const { error: souceError, source } = await stripe.createSource({
      type: 'multibanco',
      amount: invoice.PaymentRequest.total.amount,
      currency: invoice.PaymentRequest.currency,
      owner: invoice.owner,
      redirect: {
        return_url: document.location.href,
      },
    });
    if (souceError) {
      setError(souceError.message);
    } else if (source) {
      setMbSource(source as MultibancoSource);
    }
  }
  return (
    <>
      {!mbSource && <form onSubmit={onSubmitForm}>
        <label className={cls.label}>Pay by multibanco</label>
        <button className={cls.button}>Request References</button>
      </form>}
      {mbSource && (
        <div className={cls.references}>
          <label className={cls.label}>Please use these informations to complete the payment</label>
          <div className={cls.reference}>
            <label className={cls.label}>Entity</label>
            <div>{mbSource.multibanco.entity}</div>
          </div>
          <div className={cls.reference}>
            <label className={cls.label}>Reference</label>
            <div>{mbSource.multibanco.reference}</div>
          </div>
        </div>)
      }

      {error && error.split('\n').map((l, i) => <div key={i} className={classNames(cls.error)}>{l}</div>)}
    </>
  );
};

export default PayByMultibanco;
