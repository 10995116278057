import React, { FC, ReactNode, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import useInterval from '../@utils/useInterval';
import stylesheet, { Blue, Yellow, Green } from '../constants/stylesheet';
import ViewMonitor from '../@utils/ViewMonitor';
// import Shake from 'shake.js';

// display: flex;
//     padding: 5vh;
//     font-weight: bold;
//     flex-direction: column;
//     transform: translate(-50%,-50%) rotate(-90deg);
//     width: 100vh;
//     transform-origin: center;
//     height: 100vw;
//     box-sizing: border-box;
//     margin-left: 50vw;
//     margin-top: 50vh;
const useStyles = makeStyles({

  root: {
    padding: '5vh',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'bold',
    boxSizing: 'border-box',
    width: '100%',
    position: 'absolute',
    top: 0,
    '@media only screen and (orientation: portrait)': {
      transform: 'translate(-50%,-50%) rotate(-90deg)',
      transformOrigin: 'center',
      left: '50vw',
      top: '50%',
      margin: 0,
      padding: '5vw 7vh',
      width: '90vh'
    }
  },

  line: {
    fontSize: '18vmin',
    lineHeight: '20vmin',
    height: '20vmin',
    position: 'relative',
    padding: '0',
    top: '0',
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  words: {
    position: 'relative',
    height: '100%',
    flex: '1 0 auto'
  },
  reactiveZone: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 1
  },
  word: {
    position: 'absolute',
    transition: 'transform .5s ease-in-out',
    display: 'none',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  wordBefore: {
    transform: 'translateY(-120%)',
    display: 'block'
  },
  wordActive: {
    transform: 'translateY(0)',
    display: 'block'
  },
  wordAfter: {
    transform: 'translateY(120%)',
    display: 'block'
  },
  hide: {
    ...stylesheet.Hide
  },
  show: {
    ...stylesheet.Show
  }
});

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
const shuffle = <TArray extends {}>(a: TArray[]) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

const Words: FC<{ words: ReactNode[], color?: string }> = ({ words, color }) => {
  const classes = useStyles({});
  const [current, setCurrent] = useState(0);
  const [enter, setEnter] = useState(false);

  // const shuffled = shuffle(words);
  const count = words.length;
  const move = () => setCurrent(current + 1);
  useInterval(() => {
    move();
  }, enter ? 1000 : null);

  const onEnter = () => {
    if (!enter) {
      move();
      setEnter(true);
    }
  };
  const onLeave = () => {
    if (enter) {
      move();
      setEnter(false);
    }
  };

  // useEffect(() => {
  //   try {
  //     if ((DeviceMotionEvent as any).requestPermission()) {
  //       const shakeEvent = new Shake({
  //         threshold: 15, // optional shake strength threshold
  //         timeout: 1000 // optional, determines the frequency of event generation
  //       });
  //       shakeEvent.start();

  //       window.addEventListener('shake', move, false);
  //       return () => {
  //         window.removeEventListener('shake', move, false);
  //         shakeEvent.stop();
  //       }
  //     }
  //   } catch {

  //   }

  // }, [])

  const screens = Math.ceil(current / count) + 1;

  return (
    <>
      <span
        className={classes.reactiveZone}
        onTouchStart={onEnter}
        onTouchEnd={onLeave}
        onTouchCancel={onLeave}
        onMouseOver={onEnter}
        onMouseOut={onLeave}
      />
      <span className={classes.words} >
        {[...Array(screens)].map((_, i) =>
          words.map((w, j) => {
            const k = i * count + j;
            return (
              <span
                key={k}
                style={{ color }}
                className={
                  classNames(
                    classes.word,
                    k === current && classes.wordActive,
                    k < current && classes.wordBefore,
                    k > current && classes.wordAfter
                  )}
              >
                {w}
              </span>
            );
          }
          )
        )}

      </span>
    </>
  );
}
const Hero: FC = () => {
  const classes = useStyles({});
  const iDo = ['projects', 'websites', 'apps', 'IoT devices', 'consulting', 'web apps', 'code'];
  const target = ['Companies', 'Business', 'Galleries', 'Artists', 'Designers', 'Institutions', 'people'];
  
  const withAdjective = [
    'love',
    'dedication',
    'happiness',
    'C#',
    '.net',
    'React',
    'Arduino',
    'Wordpress',
    'PHP',
    'Our Cms'
  ];

  return (
    <ViewMonitor>{(intersect) =>
      <div className={classes.root}>
        <div
          className={classNames(classes.line, intersect ? classes.show : classes.hide)}
          style={{ transitionDelay: '0.2s' }}
        >
          I do&nbsp;<Words color={Blue} words={iDo} />
        </div>
        <div
          className={classNames(classes.line, intersect ? classes.show : classes.hide)}
          style={{ transitionDelay: '0.4s' }}
        >
          for&nbsp;<Words color={Green} words={['nice', 'micro', 'small', 'medium', 'big', 'huge']} />
        </div>
        <div
          className={classNames(classes.line, intersect ? classes.show : classes.hide)}
          style={{ transitionDelay: '0.6s' }}
        >
          <Words color={Yellow} words={target} />
        </div>
        <div
          className={classNames(classes.line, intersect ? classes.show : classes.hide)}
          style={{ transitionDelay: '0.8s' }}
        >
          with&nbsp;<Words words={withAdjective} />
        </div>
      </div>
    }
    </ViewMonitor>
  );
}

export default Hero;
