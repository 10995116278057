import React, { FC, useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import stylesheet from '../constants/stylesheet';

const useStyles = makeStyles({
  root: {

  },

  error: {
    fontSize: '0.8rem',
    color: 'red',
    marginTop: '1rem'
  },
  info: {
    fontSize: '0.8rem',
    color: '#b7b7b7',
    marginTop: '1rem'
  },

  label: {
    ...stylesheet.label
  },

});

const PayByPaymentRequest: FC<{ stripe: stripe.Stripe, invoice: TInvoice, onSuccess: () => void; }> = (props) => {
  const cls = useStyles({});
  const { invoice, stripe, onSuccess } = props;
  const { PaymentRequest } = invoice;
  const prButtonRef = useRef<HTMLDivElement>(null);
  const [prButton, setPrButton] = useState<stripe.elements.Element>();
  const [error, setError] = useState<string>();
  const [info, setInfo] = useState<string>();

  useEffect(() => {
    const effect = async () => {
      const paymentRequest = stripe.paymentRequest(PaymentRequest);
      const canMakePayment = await paymentRequest.canMakePayment();
      if (!canMakePayment) {
        // tslint:disable-next-line: max-line-length
        const explanation = "Apple Pay, Google Pay and Microsoft Pay need the Safari, Chrome or Ms Edge with payments configured to pay instantly";
        setInfo(explanation);
        return;
      }

      const elements = stripe.elements();
      const currentPrButton = elements.create('paymentRequestButton', {
        paymentRequest,
      });
      setPrButton(currentPrButton);

      let clientSecret = ""
      paymentRequest.on('source', ({ source: { client_secret } }) => {
        clientSecret = client_secret;
      });
      paymentRequest.on('paymentmethod', async ({ paymentMethod: { id }, complete }) => {

        const { error: IntentError } = await stripe.confirmPaymentIntent(clientSecret, {
          payment_method: id,
        });

        if (IntentError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          complete('fail');

          setError(`The payment failed (${IntentError.message || ""})`);
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          complete('success');

          // Let Stripe.js handle the rest of the payment flow.
          const cardPayment = await stripe.handleCardPayment(clientSecret);
          if (cardPayment.error) {
            // The payment failed -- ask your customer for a new payment method.
            setError(`The payment failed, please try a new payment method (${cardPayment.error.message || ""})`);
          } else {
            onSuccess();
            // The payment has succeeded.
          }

        }
      }
      );
      // paymentRequest.show();
    }
    effect();
  }, [PaymentRequest]);

  useEffect(
    () => {
      if (prButton && prButtonRef.current) {
        prButton.mount(prButtonRef.current);
      }
    },
    [prButton, prButtonRef]
  );
  return (
    <>
      <label className={cls.label}>Instant pay</label>
      {prButton &&
          <div ref={prButtonRef} />}

      {info && info.split('\n').map((l, i) => <div key={i} className={classNames(cls.info)}>{l}</div>)}
      {error && error.split('\n').map((l, i) => <div key={i} className={classNames(cls.error)}>{l}</div>)}
    </>
  )
};
export default PayByPaymentRequest;