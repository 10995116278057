import fonts from './fonts';
import responsive from './responsive';
import spacing from './spacing';

export const Blue = '#0072BC';

export const Green = 'rgb(0, 250, 0)';
export const Yellow = 'rgb(250,235,00)';
export const Black = 'rgb(26,26,26)';

type directions = 'top' | 'bottom' | 'left' | 'right' | 'all' | 'sides';

const prefixed = (prefix: string, value: number, multi: number = 1, dir: directions = 'sides') => ({
    [prefix ? `${prefix}Left` : 'left']:
        (dir === 'left' || dir === 'sides' || dir === 'all') && value * multi || undefined,
    [prefix ? `${prefix}Right` : 'right']:
        (dir === 'right' || dir === 'sides' || dir === 'all') && value * multi || undefined,
    [prefix ? `${prefix}Bottom` : 'bottom']:
        (dir === 'bottom' || dir === 'all') && value * multi || undefined,
    [prefix ? `${prefix}Top` : 'top']:
        (dir === 'top' || dir === 'all') && value * multi || undefined,
});

export const bodyFontSize = {
    'Mobile': 18,
    'Tablet': 18,
    'Desktop': 18
}
export default {
    'Body': {
        ...fonts.Brown,
        backgroundColor: '#fffefa',
        [responsive.Mobile]: {
            fontSize: bodyFontSize.Mobile,
        },
        [responsive.Tablet]: {
            fontSize: bodyFontSize.Tablet,
        },
        [responsive.Desktop]: {
            fontSize: bodyFontSize.Desktop,
        }
    },
    'SmallText': {
        [responsive.Mobile]: {
            fontSize: '.7rem',
        },
        [responsive.Tablet]: {
            fontSize: '.7rem',
        },
        [responsive.Desktop]: {
            fontSize: '.7rem',
        }
    },
    'HeroTitle': {
        [responsive.Mobile]: {
            fontSize: '1.5rem',
        },
        [responsive.Tablet]: {
            fontSize: '1.5rem',
        },
        [responsive.Desktop]: {
            fontSize: '1.5rem',
        }
    },
    'SmallTitle': {
        lineHeight: 1.25,
        '@media screen and (max-width: 1024px)': {
            '&': {
                fontSize: '1.25rem'
            }
        },
        '@media screen and (min-width: 1025px)': {
            '&': {
                fontSize: '1.75rem'
            }
        }
    },
    'Title': {
        lineHeight: 1,
        '@media screen and (max-width: 1024px)': {
            '&': {
                fontSize: '2rem'
            }
        },
        '@media screen and (min-width: 1025px)': {
            '&': {
                fontSize: '3rem'
            }
        }
    },
    'SliderTitle': {
        lineHeight: 1,
        '@media screen and (max-width: 1024px)': {
            '&': {
                fontSize: '2.5rem'
            }
        },
        '@media screen and (min-width: 1025px)': {
            '&': {
                fontSize: '5.5rem'
            }
        }
    },
    'PaddingGutter': (multi: number = 1, dir: directions = 'sides') => ({
        [responsive.Mobile]: prefixed('padding', spacing.Gutter.Mobile, multi, dir),
        [responsive.Tablet]: prefixed('padding', spacing.Gutter.Tablet, multi, dir),
        [responsive.Desktop]: prefixed('padding', spacing.Gutter.Desktop, multi, dir),
    }),
    'MarginGutter': (multi: number = 1, dir: directions = 'sides') => ({
        [responsive.Mobile]: prefixed('margin', spacing.Gutter.Mobile, multi, dir),
        [responsive.Tablet]: prefixed('margin', spacing.Gutter.Tablet, multi, dir),
        [responsive.Desktop]: prefixed('margin', spacing.Gutter.Desktop, multi, dir)
    }),
    'PostionGutter': (multi: number = 1, dir: directions = 'sides') => ({
        [responsive.Mobile]: prefixed('', spacing.Gutter.Mobile, multi, dir),
        [responsive.Tablet]: prefixed('', spacing.Gutter.Tablet, multi, dir),
        [responsive.Desktop]: prefixed('', spacing.Gutter.Desktop, multi, dir)
    }),
    'Show': {
        opacity: 1
    },
    'Hide': {
        opacity: 0,
        transition: '1s opacity linear',
    },
    'ObjectFit': {
        fontFamily: '"object-fit: cover;"'
    },
    'button': {
        border: 'none',
        borderRadius: 4,
        outline: 'none',
        textDecoration: 'none',
        color: '#fff',
        background: '#32325d',
        whiteSpace: 'nowrap' as 'nowrap',
        display: 'block',
        padding: ' 0.5rem 14px',
        boxShadow: '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
        letterSpacing: '0.025em',
        WebkitTransition: 'all 150ms ease',
        transition: 'all 150ms ease',
        width: '100%',
        '&:hover': {
            cursor: 'pointer',
            transform: 'translateY(-1px)',
            boxShadow: '0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08)',
            backgroundColor: '#43458b',
        }
    },
    'label': {
        color: 'gray',
        fontSize: '0.8rem',
        display: 'block',
        marginBottom: '1rem',
        textAlign: 'center' as 'center'
    }
};