export const handleApiResponse = <Model>(response: Response): Promise<Model> => {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            // return model if it was returned in the response
            var contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                response.json().then(json => {
                    return resolve(json as Model);
                }).catch((reason) => {
                    // impossible to parse json
                    return reject(response.text());
                });
            } else {
                resolve();
            }
        } else {
            // return error message from response body
            response.text().then(reason => reject(reason));
        }
    });
};