import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import smoothscroll from 'smoothscroll-polyfill';



/**
 * polyfill for document.scrollingElement
 * https://github.com/yangg/scrolling-element
 */
(function () {
    if (document.scrollingElement) {
        return
    }
    var element: HTMLElement | null = null;
    function scrollingElement() {
        if (element) {
            return element
        } else if (document.body.scrollTop) {
            // speed up if scrollTop > 0
            return (element = document.body)
        }
        var iframe = document.createElement('iframe')
        iframe.style.height = '1px'
        document.documentElement.appendChild(iframe)
        var doc = iframe.contentWindow!.document
        doc.write('<!DOCTYPE html><div style="height:9999em">x</div>')
        doc.close()
        var isCompliant = doc.documentElement.scrollHeight > doc.body.scrollHeight
        iframe.parentNode!.removeChild(iframe)
        return (element = isCompliant ? document.documentElement : document.body)
    }
    Object.defineProperty(document, 'scrollingElement', {
        get: scrollingElement
    })
})();

// kick off the polyfill!
smoothscroll.polyfill();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
