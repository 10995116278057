import * as React from 'react';
import { handleApiResponse } from '../@utils/auth-header';
import { useContext, FC, useState, useEffect } from 'react';

type TUser = {};
type Api = {

    fetching?: boolean;

    // user?: TUser;

    loading?: boolean;


    fetchApi: <TResult>(endpoint: string, options?: RequestInit) => Promise<TResult>;

    // login: (username: string, password: string) => Promise<TUser>;
    // logout: () => Promise<void>;

};

export const ApiContext = React.createContext<Api>({} as never);

type ApiProviderProps = { apiUrl: string };
export const ApiProvider: FC<ApiProviderProps> = ({ apiUrl, children }) => {

    const [fetching, setFetching] = useState<boolean>();
    // const [user, setUser] = useState<TUser>();
    const [loading, setLoading] = useState<boolean>();

    const fetchApi = async <TResult extends {}>(endpoint: string, options?: RequestInit) => {

        const requestOptions: RequestInit = {
            method: 'GET',
            // credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            ...options
        };

        const response = await fetch(`${apiUrl}/${endpoint}`, requestOptions);
        const entry = await handleApiResponse<TResult>(response);

        return entry;
    };


    // const login = async (username: string, password: string) => {
    //     const requestOptions = {
    //         method: 'POST',
    //         body: JSON.stringify({ Email: username, Password: password })
    //     };
    //     setFetching(true);
    //     const _user = await _fetch<TUser>(`account/signin`, requestOptions);

    //     if (!_user) {
    //         setFetching(false);
    //         setUser(undefined);
    //         throw new Error('impossible to log in');
    //     }
    //     setFetching(false);
    //     setUser(_user);
    //     return _user;
    // };

    // const logout = async () => {
    //     const requestOptions = {
    //         method: 'POST',
    //     };

    //     await _fetch(`account/signout`, requestOptions);

    //     setFetching(false);
    //     setUser(undefined);
    // };
    // useEffect(() => {
    //     const componentDidMount = async () => {
    //         try {
    //             const response = await _fetch<TUser>(`account`);

    //             setLoading(false);
    //             setUser(response || undefined);
    //         } catch {
    //             setLoading(false);
    //             setUser(undefined);
    //         }

    //     };
    //     componentDidMount();
    // }, []);
    return (
        <ApiContext.Provider value={{ fetching, loading, fetchApi }}>
            {children}
        </ApiContext.Provider>
    );
}

export const useApi = () => useContext(ApiContext);