
import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import stylesheet, { Blue, Green } from '../constants/stylesheet';
import Script from 'react-load-script';
import fonts from '../constants/fonts';
import PayByPaymentRequest from './PayByPaymentRequest';
import PayByCard from './PayByCard';
import PayByMultibanco from './PayByMultibanco';

declare var Stripe: stripe.StripeStatic;
//     display: flex;
//     padding: 5vh;
//     font-weight: bold;
//     flex-direction: column;
//     transform: translate(-50%,-50%) rotate(-90deg);
//     width: 100vh;
//     transform-origin: center;
//     height: 100vw;
//     box-sizing: border-box;
//     margin-left: 50vw;
//     margin-top: 50vh;
const useStyles = makeStyles({
  root: {
    padding: '10vmin',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'bold',
    boxSizing: 'border-box',
    width: '100%',
    position: 'absolute',
    top: 0,
  },
  header: {
    fontSize: '2rem',
    paddingBottom: '2rem',
    margin: '0 auto'
  },
  headerName: {
    color: Blue,
  },
  headerInvoice: {
    
  },
  headerInvoiceNumber: {
    color: Green
  },
  headerPriceLine: {
    color: 'gray'
  },
  headerPrice: {

  },
  Payments: {
    display: 'flex',
    flex: '1 1 auto',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  Payment: {
    flex: '1 0 100%',
    minWidth: 300,
    padding: '3rem',
    boxSizing: 'border-box',
    maxWidth: 600
  },

  error: {
    fontSize: '0.8rem',
    color: 'red',
    marginTop: '1rem'
  },

  success: {
    fontSize: '2rem',
    paddingBottom: '2rem',
    margin: '0 auto',
    maxWidth: 600,
  },
  loading: {
    fontSize: '2rem',
    paddingBottom: '2rem',
    margin: '0 auto',
    color: 'gray',
    maxWidth: 600,
  }
});

const Price: FC<{ currency: string, amount: number }> = ({ currency, amount }) => {
  return (
    <span>
      {currency.toUpperCase()} {(amount / 100).toFixed(2)}
    </span>
  );
}

const STRIPE_PUBLIC_KEY = 'pk_live_wUBT1eZNGhXWtgLAycspotUV00BZfxVfNF';
const Pay: FC<{ sku: string }> = ({ sku }) => {
  const cls = useStyles();

  const [invoices, setInvoices] = useState<{ [sku: string]: TInvoice }>();
  const [invoice, setInvoice] = useState<TInvoice>();
  const [stripe, setStripe] = useState<stripe.Stripe>();

  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<boolean>();

  useEffect(
    () => {
      const effect = async () => {
        try {
          const response = await fetch('invoices.json');
          const toset = await response.json();
          setInvoices(toset);
        } catch (ex) {
          setError(`impossible to load invoices\n please try again later`);
        }
      }
      effect();
    }, []
  );

  useEffect(
    () => {
      if (!invoices) { return; }
      const current = invoices[sku];
      if (!current) {
        setError(`impossible to find invoices ${sku}`);
        return;
      }
      setInvoice(current);
    }, [sku, invoices]
  );
// {invoice &&
        //   invoice.PaymentRequest.displayItems &&
        //   invoice.PaymentRequest.displayItems.map(item =>
        //     <div className={cls.headerPriceLine} key={item.label}>
        //     {item.label} <Price currency={invoice.PaymentRequest.currency} amount={item.amount} />
        //     </div>
        //   )
        // }
  const onSuccess = () => {
    setSuccess(true);
  };
  const loading = !error && !success && (!invoice || !stripe);

  const onLoadStripe = () => {
    setStripe(Stripe(STRIPE_PUBLIC_KEY));
  };
  const onLoadErrorStripe = () => {
    setError(`impossible to load Stripe`);
  };
  return (
    <div className={cls.root}>
      <header className={cls.header}>
        <div className={cls.headerName}>Romain Preston</div>
        <div className={cls.headerInvoice}>
          Pay invoice {invoice ?
            <span className={cls.headerInvoiceNumber}>{invoice.number}</span> :
            'loading...'}
        </div>
        
        {invoice &&
          <div className={cls.headerPrice}>
            <Price currency={invoice.PaymentRequest.currency} amount={invoice.PaymentRequest.total.amount} />
          </div>}
      </header>
      <Script
        url="https://js.stripe.com/v3"
        onLoad={onLoadStripe}
        onError={onLoadErrorStripe}
      />
      {
        !success && !loading && invoice && stripe && (
          <div className={cls.Payments}>
            <div className={cls.Payment}>
              <PayByPaymentRequest invoice={invoice} stripe={stripe} onSuccess={onSuccess} />
            </div>
            <div className={cls.Payment}>
              <PayByCard invoice={invoice} stripe={stripe} onSuccess={onSuccess} />
            </div>
            <div className={cls.Payment}>
              <PayByMultibanco invoice={invoice} stripe={stripe} onSuccess={onSuccess} />
            </div>
          </div>
        )
      }

      {
        error && (
          <div className={cls.error}>
            {error.split('\n').map((l, i) => <div key={i}>{l}</div>)}
          </div>
        )
      }
      {
        loading && (
          <>
            {!invoice &&
              <div className={cls.loading}>
                <div>looking for</div>
                <div>your invoice</div>
                <div>{sku}</div>
              </div>}
            {!stripe &&
              <div className={cls.loading}>
                <div>loading</div>
                <div>payment</div>
              </div>}
          </>
        )
      }
      {
        success &&
        <div className={cls.success}>
          <div>Thank you !<br />Your Payment has been processed successfully.</div>

        </div>
      }
    </div >
  );
}
export default Pay;
