import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import stylesheet, { Yellow } from './constants/stylesheet';
import Hero from './components/Hero';
import Pay from './components/Pay';
import classNames from 'classnames';
import Project from './components/Project';
import { GetQueryVariable } from './@utils/url';
import { ApiProvider } from './@context/Api';

const site: Site = {
  projects: [
    {
      uid: 'unhcr',
      title: 'UNHCR — Mon pays est un camp',
      sections: [
        {
          type: 'MediaSection',
          slides: [
            {
              type: 'IFrameSlide',
              src: 'https://www.unhcr.org/fr-fr/monpaysestuncamp/',
              title: 'unhcr.org - Mon pays est un camp'
            }
          ]
        }

      ]
    },
    {
      uid: 'the-backup-project',
      title: 'the backup project',
      sections: [
        {
          type: 'MediaSection',
          slides: [
            {
              type: 'IFrameSlide',
              src: 'https://www.the-backup-project.com',
              title: 'the-backup-project.com - the backup project'
            }
          ]
        }

      ]
    }
  ]
};

const useStyles = makeStyles({
  '@global': {
    'html, body': {
      ...stylesheet.Body
    },
    'a:hover': {
      color: Yellow
    },
    'em': {
      fontStyle: 'italic'
    },
    'button': {
      border: 0,
      backgroundColor: 'transparent'
    }
  },
  root: {
    height: '100%'

  },
  content: {
    // height: '300%',
    position: 'relative',
    marginTop: '100vh'
  },
  header: {
    position: 'absolute',
    left: '0',
    top: '0',
    right: '0',
    padding: '5vh',
    textAlign: 'center',
    fontSize: '5vh',
    zIndex: 1
  },
  headerName: {
    fontSize: '10vmin'
  },
  headerMail: {
    fontSize: '6vmin',
    display: 'block',
    maxWidth: 700,
    margin: '0 auto'
  },

});

const App: FC = () => {
  const classes = useStyles();

  const sku =
    (GetQueryVariable("p") && GetQueryVariable("p").replace("/pay:", "")) ||
    (document.location.hash.startsWith("#pay:") && document.location.hash.replace("#pay:", ""));
  return (
    <ApiProvider apiUrl="https://it6d5v1x6d.execute-api.eu-west-1.amazonaws.com/dev/api">
      <div className={classes.root}>
        {!sku &&
          <>
            <Hero />
            <div className={classes.content}>
              <header className={classes.header}>
                <h1 className={classes.headerName}>Romain Preston</h1>
                <p className={classes.headerMail}>
                  while I build up this website,
                  don't hesitate to email me: <a href="mailto:hello@romainpreston.com">hello@romainpreston.com</a>
                </p>
              </header>
              
            </div>
          </>
        }
        {sku && <Pay sku={sku} />}
      </div>
    </ApiProvider>
  );
}

export default App;
