import React, { FC, useState, useEffect, useRef, FormEvent } from 'react';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import stylesheet, { Blue, Green } from '../constants/stylesheet';
import fonts from '../constants/fonts';
import { useApi } from '../@context/Api';

const useStyles = makeStyles({
  root: {
    '& .StripeElement': {
      flex: '1 1 auto',
      boxSizing: 'border-box',
      height: '40px',
      padding: '10px 12px',
      border: '1px solid transparent',
      borderRadius: '4px',
      backgroundColor: 'white',
      width: '100%',
      marginBottom: '1rem',
      boxShadow: '0 1px 3px 0 #e6ebf1',

      transition: 'box-shadow 150ms ease',
      '&--focus': {
        boxShadow: '0 1px 3px 0 #cfd7df'
      },
      '&--invalid': {

        borderColor: '#fa755a'
      },
      '&--webkit-autofill': {
        backgroundColor: '#fefde5 !important'
      }
    }
  },
  fields: {

  },
  error: {
    fontSize: '0.8rem',
    color: 'red',
    marginTop: '1rem'
  },
  label: {
    ...stylesheet.label
  },
  button: {
    flex: '1 1 auto',
    ...stylesheet.button,
    display: 'flex',
    justifyContent: 'center',
  },
  buttonProcessing: {
    background: 'green',
    pointerEvents: 'none',
    boxShadow: '0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08)'
  },
  progress: {
    width: '1rem',
    height: '1rem',
    marginRight: '0.5rem',
    color: 'white',
  }

});

const cardStyle = {
  base: {
    ...fonts.Brown,
    color: '#32325d',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

const PayByCard: FC<{ stripe: stripe.Stripe, invoice: TInvoice, onSuccess: () => void; }> = (props) => {
  const cls = useStyles();
  const { invoice, stripe, onSuccess } = props;

  const cardElementsRef = useRef<HTMLDivElement>(null);
  const [cardElements, setCardElements] = useState<stripe.elements.Element>();
  const [error, setError] = useState<string>();
  const [processing, setProcessing] = useState(false);
  const { fetchApi } = useApi();

  useEffect(
    () => {
      const elements = stripe.elements({ fonts: [{ cssSrc: '/assets/fonts.css' }] });
      const card = elements.create('card', { style: cardStyle });
      setCardElements(card);
    },
    [invoice, stripe]
  );

  useEffect(
    () => {
      if (cardElements && cardElementsRef.current) {
        cardElements.mount(cardElementsRef.current);
        // Handle real-time validation errors from the card Element.
      }
    },
    [cardElements, cardElementsRef]
  );

  const onSubmitForm = async (ev: FormEvent) => {
    ev.preventDefault();

    if (!stripe || !cardElements || !invoice) { return; }
    setProcessing(true);
    const { token, error: TokenError } = await stripe.createToken(cardElements);
    if (TokenError) {
      setProcessing(false);
      setError(TokenError.message);
      return;
    } else if (token) {
      try {
        await fetchApi<void>('stripe/charge', { method: 'post', body: JSON.stringify({ invoice, token }) });
        setProcessing(false);
        onSuccess();
      } catch {
        setProcessing(false);
        setError("there has been a problem validating the payment, please contact us");
      }
      
    }
  }
  return (
    <>
      {cardElements && <form onSubmit={onSubmitForm} className={cls.root}>
        <label className={cls.label} htmlFor="card-element">Credit or debit card</label>
        <div className={cls.fields}>
          <div ref={cardElementsRef} />
          <button className={classNames(cls.button, processing && cls.buttonProcessing)}>
            {processing ? (
              <>
                <CircularProgress size="1rem" className={cls.progress} /> Processing...
              </>) :
              "Submit Payment"}
          </button>
        </div>
      </form>}
      {error && error.split('\n').map((l, i) => <div key={i} className={classNames(cls.error)}>{l}</div>)}
    </>
  );
};
export default PayByCard;