export const mobileSize = 768;

export const tabletSize = 1199;

const mediaQueries = {
    'Mobile': `@media screen and (max-width: ${mobileSize - 1}px)`,
    'Tablet': `@media screen and (min-width: ${mobileSize}px) and (max-width: ${tabletSize - 1}px)`,
    'Desktop': `@media screen and (min-width: ${tabletSize}px)`
};

export default mediaQueries;