import Observer, { Props as ObserverProps } from '@researchgate/react-intersection-observer';
import * as React from 'react';
import { Subtract } from 'utility-types';
import 'intersection-observer';

type Direction = 'up' | 'down';
type Props =
    { once?: boolean } &
    Partial<Subtract<ObserverProps, { children?: {} | null }>> &
    {
        children: (isIntersecting: boolean, direction?: Direction) => React.ReactElement<any>;
    };

type State = {
    isIntersecting: boolean,
    direction: Direction
};
export default class ViewMonitor extends React.Component<Props, State> {

    public readonly state: State = {
        isIntersecting: false,
        direction: 'up'
    }

    private top = 0;

    constructor(props: Props) {
        super(props);
        this._handleChange = this._handleChange.bind(this);
    }

    render() {
        const { children, ...rest } = this.props;
        const { isIntersecting, direction } = this.state;
        const element = children(isIntersecting, direction);
        return (
            <Observer {...rest} onChange={this._handleChange}>
                {element}
            </Observer>
        );
    }

    private _handleChange({ isIntersecting, boundingClientRect }: IntersectionObserverEntry, unobserve: () => void) {
        const { top } = boundingClientRect;
        const { once } = this.props;
        const direction = this.top > top ? 'up' : 'down';
        this.top = top;
        this.setState((state) => 
            ({ isIntersecting : once ? isIntersecting || state.isIntersecting : isIntersecting, direction })
        );
    }

}
